<!--
 * @Description: 评级审核
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-08-20 17:23:46
 -->
 <template>
  <div class="drawer-chart-detail-modal">
    <a-drawer
      title="评级审核"
      width="1050px"
      placement="right"
      @close="handleHideThisModal"
      :visible="levelAuditVisible"
    >
     <!-- <a-form-item label="渠道" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <div v-if="userInfo && userInfo.auth_type ===2">淘宝</div>
        <div v-if="userInfo && userInfo.auth_type ===1">抖音</div>
      </a-form-item>-->
      <a-form-item
        label="手机号"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >{{userInfo && userInfo.history && userInfo.history.mobile || ''}}</a-form-item>

      <!-- 抖音 -->
      <a-form-item
        v-if="userInfo && userInfo.auth_type ===1"
        label="抖音号"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >{{userInfo && userInfo.history && userInfo.history.douyin_account || ''}}</a-form-item>
      <template v-if="userInfo && userInfo.auth_type ===2">
        <a-form-item
          label="主播昵称"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
        >{{userInfo && userInfo.history && userInfo.history.nickname}}</a-form-item>
      </template>
      <a-form-item label="图片凭证" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-row
          v-if="userInfo && userInfo.history && userInfo.history.pic_list.length > 0"
          :gutter="5"
          id="cert-image-wrap"
        >
          <a-col v-for="(cert, index) in userInfo.pic_list" :key="index" :span="6">
            <img
              class="cert-image"
              :data-original="cert"
              :src="cert + '?x-oss-process=image/resize,m_fill,h_140,w_140'"
            />
          </a-col>
        </a-row>
      </a-form-item>

      <a-form-item
        label="申请时间"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >{{userInfo && userInfo.create_time || ''}}</a-form-item>
      <a-divider></a-divider>
      <div v-if="type !== 'readonly'">
        <div v-if="userInfo.auth_type===1">
          <div class="flex-t">
            <h2>抖音授权信息</h2>
            <div>
              <span class="mr-20">{{userInfo.auth && userInfo.auth.update_time}}</span>
            </div>
          </div>
          <div>
            <a-form-item
              label="昵称"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >{{userInfo.auth && userInfo.auth.nickname}}</a-form-item>
            <a-form-item
              label="粉丝数量"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >{{userInfo.auth && userInfo.auth.fans_count && userInfo.auth.fans_count !== -1 ? userInfo.auth.fans_count: '暂无数据'}}</a-form-item>
            <a-form-item
              label="视频数量"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >{{userInfo.auth && userInfo.auth.video_count}}</a-form-item>
            <a-form-item
              label="性别"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >{{genderType[userInfo.auth.gender]}}</a-form-item>
            <a-form-item
              label="地区"
              :label-col="{ span: 5 }"
              :wrapper-col="{ span: 19 }"
            >{{userInfo.auth && userInfo.auth.province}}{{userInfo.auth && userInfo.auth.city}}</a-form-item>
          </div>

          <a-divider></a-divider>
        </div>
      </div>

      <h2>审核结果</h2>
      <a-form :form="levelAuditForm" @submit="handlePostAudit">
        <template v-if="userInfo.auth_type === 2">
          <a-form-item label="主播昵称" :label-col="{ span: 5 }" :wrapper-col="{ span: 8 }">
            <a-input
              v-decorator="['nickname', { rules: [{ required: true, message: '请输入主播昵称' }] }]"
              placeholder="请输入主播昵称"
              :maxLength="25"
              v-if="type==='audit'"
            />
            <span v-else>{{userInfo.nickname}}</span>
          </a-form-item>
          <a-form-item label="折一评级" :label-col="{ span: 5 }" :wrapper-col="{ span: 8 }">
            <a-select
              placeholder="请选择"
              v-decorator="['level', { rules: [{ required: true, message: '请选择折一评级' }] }]"
              v-if="type==='audit'"
            >
              <a-select-option
                v-for="i in userLevelOption"
                :value="i.value"
                :key="i.value"
              >{{i.label}}</a-select-option>
            </a-select>
            <span v-else>LV{{userInfo.level}}</span>
          </a-form-item>
          <a-form-item label="加入白名单" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
            <a-radio-group
              v-decorator="[ 'is_join_white_list', { rules: [{ required: true, message: '请选择是否加入白名单' }] } ]"
              v-if="type==='audit'"
            >
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
            <span v-else>{{userInfo.is_join_white_list ? '是' : '否'}}</span>
          </a-form-item>
        </template>

        <!--抖音-->
        <template v-if="userInfo.auth_type === 1">
          <a-form-item label="抖音号" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
            <a-input
              style="width:300px;"
              v-decorator="['douyin_account', { rules: [{ required: true, message: '请输入抖音号' }, { validator: checkNotChinese }] }]"
              placeholder="请输入抖音号"
              v-if="type==='audit'"
            />
            <span v-else>{{userInfo.douyin_account}}</span>
          </a-form-item>
          <a-form-item label="抖音等级" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
            <a-select
              placeholder="请选择"
              style="width:300px;"
              v-decorator="['taobao_douyin_level', { rules: [{ required: true, message: '请选择淘宝等级' }] }]"
              v-if="type==='audit'"
            >
              <a-select-option
                v-for="i in douyinLevelOption"
                :value="i.value"
                :key="i.value"
              >{{i.label}}</a-select-option>
            </a-select>
            <span v-else>LV{{userInfo.taobao_douyin_level}}</span>
          </a-form-item>
          <a-form-item label="折一评级" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
            <a-select
              placeholder="请选择"
              style="width:300px;"
              v-decorator="['level', { rules: [{ required: true, message: '请选择折一评级' }] }]"
              v-if="type==='audit'"
            >
              <a-select-option
                v-for="i in userLevelOption"
                :value="i.value"
                :key="i.value"
              >{{i.label}}</a-select-option>
            </a-select>
            <span v-else>LV{{userInfo.level}}</span>
          </a-form-item>
          <a-form-item label="加入白名单" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
            <a-radio-group
              v-decorator="[ 'is_join_white_list', { rules: [{ required: true, message: '请选择是否加入白名单' }] } ]"
              v-if="type==='audit'"
            >
              <a-radio :value="1">是</a-radio>
              <a-radio :value="0">否</a-radio>
            </a-radio-group>
            <span v-else>{{userInfo.is_join_white_list ? '是' : '否'}}</span>
          </a-form-item>
        </template>
        <template v-if="type!=='audit'">
          <a-form-item label="审核人" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
            <span>{{userInfo.audit_user_name}}</span>
          </a-form-item>
          <a-form-item label="审核时间" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
            <span>{{userInfo.create_time}}</span>
          </a-form-item>
        </template>
        <div class="mx-bottom" v-if="type==='audit'">
          <div class="level-audit-tip">
            <dl>
              <dt>说明：</dt>
              <dd v-if="userInfo.auth_type === 2">1. 请核实截图中的昵称和用户填写的昵称是否一致;</dd>
              <dd v-if="userInfo.auth_type === 1">1. 请确保抖音号、图片和授权账号为同一人的信息;</dd>
              <dd>2. 请将截图中的官方等级填写到审核结果中;</dd>
              <dd>3. 加入白名单的用户后期系统将不再自动更新评级（针对内部主播）;</dd>
              <dd>
                4. 评级标准
                <table>
                  <tbody>
                    <tr>
                      <th>折一评级</th>
                      <th>公司等级</th>
<!--                      <th>淘宝等级</th>-->
                      <th>抖音等级</th>
                    </tr>
                    <tr>
                      <td>LV1</td>
                      <td></td>
<!--                      <td>V1</td>-->
                      <td>LV1</td>
                    </tr>
                    <tr>
                      <td>LV2</td>
                      <td></td>
<!--                      <td>V2</td>-->
                      <td>LV2、LV3</td>
                    </tr>
                    <tr>
                      <td>LV3</td>
                      <td>B/C级</td>
<!--                      <td>V3</td>-->
                      <td>LV4</td>
                    </tr>
                    <tr>
                      <td>LV4</td>
                      <td>新人A级</td>
<!--                      <td>V4</td>-->
                      <td>LV5、LV6</td>
                    </tr>
                    <tr>
                      <td>LV5</td>
                      <td>A/S级</td>
<!--                      <td>V5</td>-->
                      <td>LV7</td>
                    </tr>
                  </tbody>
                </table>
              </dd>
            </dl>
          </div>
          <div class="footer-btns">
            <a-button @click="handleHideThisModal" class="mr-20">取消</a-button>
            <a-button type="primary" html-type="submit">提交</a-button>
          </div>
        </div>
      </a-form>
    </a-drawer>
  </div>
</template>
<script>
import {
  douyinLevelOption,
  taobaoLevelOption,
  userLevelOption,
  genderType,
} from "@/const/";
import {
  postUserLevel,
  getUserLevelDetail,
  getQueryTaobaoAuth
} from "@/service/user";
import {checkNumber, checkTbkPid,checkIsNotZero,checkNotChinese} from '@/utils/validator'
import "viewerjs/dist/viewer.css";
import Viewer from "viewerjs";
import { pidAttrOptions } from '@/const/index';

export default {
  name: "DrawerLevelAudit",
  components: {},
  props: {
    id: {
      type: Number
    },
    type: {
      type: String,
      default: "audit"
    }
  },
  data() {
    return {
      douyinLevelOption,
      taobaoLevelOption,
      userLevelOption,
      genderType,
      checkNumber,
      checkTbkPid,
      checkIsNotZero,
      checkNotChinese,
      levelAuditVisible: true,
      levelAuditForm: this.$form.createForm(this),
      userInfo: {},
      audit_id: null,
      postingTb: false,
      pidAttrOptions
    };
  },
  mounted() {
    this.getAuditUserInfo();
  },
  methods: {
    // 重新查询
    async getNewTaobao() {
      this.postingTb = true;
      const params = { auth_id: this.audit_id };
      const { err, res } = await getQueryTaobaoAuth(params);
      this.postingTb = false;
      if (!err) {
        const _data = JSON.parse(JSON.stringify(res.data));
        this.userInfo.auth = _data;
        // this.userInfo.fans_count = _data.fans_count ? _data.fans_count : null;
        // this.userInfo.rank = _data.rank ? _data.rank : null;
        // this.userInfo.areas =
        //   _data.areas && _data.areas.length ? _data.areas.join(",") : "";
        if (res.success) {
          this.userInfo.update_time = _data.update_time
            ? _data.update_time
            : null;
          this.$message.success("查询成功，结果已更新");
        }
      }
    },
    // 隐藏modal
    handleHideThisModal(page = -1) {
      this.$emit("callback", page);
    },
    // 提交审核
    async handlePostAudit(e) {
      e.preventDefault();
      this.levelAuditForm.validateFields(async (err, value) => {
        if (!err) {
          const data = { ...value };
          data.before_id = this.id;
          const { err, res } = await postUserLevel(data);
          if (!err) {
            if (res.success) {
              this.$message.success("审核成功！");
              this.handleHideThisModal(0);
            }
          }
        }
      });
    },
    // 获取用户信息
    async getAuditUserInfo() {
      const { err, res } = await getUserLevelDetail(this.id);
      if (!err) {
        if (res.success) {
          const data = JSON.parse(JSON.stringify(res.data));
          if (data.taobao_douyin_level === 0) {
            data.taobao_douyin_level = null;
          }
          if (!data.pid_attr) {
            data.pid_attr = undefined;
          }
          if (this.type === 'audit') {
            data.history = {
              apply_time: data.apply_time,
              douyin_account: data.douyin_account,
              id: undefined,
              mobile: data.mobile,
              nickname: data.nickname,
              pic_list: data.pic_list,
              userid: data.userid,
              pid_attr: data.pid_attr,
              pid: data.pid,
            }
          }
          this.userInfo = data;
          this.audit_id = data.auth && data.auth.id;
          this.$nextTick(() => {
            this.levelAuditForm.setFieldsValue(data);
            // 初始化 相册功能
            new Viewer(document.getElementById("cert-image-wrap"), {
              url: "data-original"
            });
          });
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.iframe-auto {
  width: 380px;
  margin: 0 auto 20px;
}
.n-table {
  th,
  td {
    font-weight: normal;
    padding: 5px;
  }
  th {
    text-align: right;
  }
}
.pic-box {
  img {
    height: 150px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
.fullwidth-chart {
  width: 100%;
}
.fans-chart {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  li {
    width: 500px;
    height: 500px;
  }
}
.account-info-list {
  display: flex;
  flex-wrap: wrap;
  li {
    width: 250px;
  }
}
.level-audit-tip {
  margin-bottom: 30px;
  line-height: 150%;
  background: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 5px;
  padding: 10px;
  width: 600px;
  table {
    width: 540px;
    margin: 5px auto 0;
    border: 1px solid #bbb;
    th,
    td {
      padding: 4px;
      border: 1px solid #bbb;
    }
    th {
      background: #eee;
    }
  }
}
.mx-bottom {
  margin-left: 200px;
}
</style>
