<!--
 * @Description: 评级审核
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-06-09 15:28:40
 * @LastEditTime: 2020-06-18 18:36:49
-->
<template>
  <div id="audit">
    <a-tabs @change="tabChangeCallback" type="card">
      <a-tab-pane tab="申请列表" key="1"></a-tab-pane>
      <a-tab-pane tab="审核记录" key="2"></a-tab-pane>
    </a-tabs>
    <div class="tab-content">
      <a-form
        class="filter-condition-form"
        layout="inline"
        :form="form"
        @submit="handleFormSubmit"
        v-auth="'user_manage_audit_list'"
      >
        <a-form-item>
          <a-input
            class="keyword-input"
            v-decorator="['mdtz']"
            placeholder="手机号/抖音昵称"
            allowClear
            @change="handleFormInputChange"
          />
        </a-form-item>
        <!--<a-form-item>
          <a-select style="width: 120px" placeholder="渠道筛选" v-decorator="['auth_type']" allowClear>
            <a-select-option value="1">抖音</a-select-option>
            <a-select-option value="2">淘宝</a-select-option>
          </a-select>
        </a-form-item>-->
        <a-form-item>
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-form-item>
      </a-form>
      <a-table
        :scroll="{ x: 1200, y: tableScrollY }"
        v-auth="'user_manage_level_page'"
        :rowKey="record => record.id"
        :columns="columns"
        :dataSource="list"
        :pagination="pagination"
        :loading="listLoading"
        :locale="{ emptyText: '暂无记录' }"
        @change="handleTableChange"
      >
        <!-- 渠道插槽 -->
        <!--<template slot="channel_tag" slot-scope="record">
          <a-tag v-if="record.auth_type===2" color="orange">淘宝</a-tag>
          <a-tag v-if="record.auth_type===1" color="purple">抖音</a-tag>
        </template>-->
        <!-- 原有评级 -->
        <template slot="before_level_slot" slot-scope="record">
          <span v-if="record.before_level" color="orange">LV{{record.before_level}}</span>
          <span v-else>-</span>
        </template>
        <!-- 审核结果 -->
        <template slot="level_slot" slot-scope="record">
          <span v-if="record.level" color="orange">LV{{record.level}}</span>
          <span v-else>-</span>
        </template>
        <!-- 申请列表操作插槽 -->
        <template slot="operation" slot-scope="record">
          <a-button
            size="small"
            @click="handleAuditClick(record.id)"
            v-auth="'user_manage_level_audit'"
          >审核</a-button>
        </template>
        <!-- 审核记录插槽 -->
        <template slot="status" slot-scope="record">
          <span
            :class="{ 'passed': USER_STATUS.PASSED.VALUE == record.status, 'not-pass': USER_STATUS.NOT_PASS.VALUE == record.status }"
          >{{getStatusText(record.status)}}</span>
        </template>
        <template slot="record-operation" slot-scope="record">
          <a-button size="small" @click="handleShowAuditDetailDrawer(record.id)">查看</a-button>
        </template>
      </a-table>
    </div>
    <!-- 审核弹窗 -->
    <DrawerLevelAudit
      v-if="auditDialogVisible"
      :id="currentLevelId"
      :type="currentLevelDrawerType"
      @callback="handleHideAuditModal"
    />
  </div>
</template>

<script>
import {
  getUserLevelList,
  auditUser,
  getTaobaoFans
} from "../../../service/user";
import { USER_STATUS } from "../../../const/index";
import { checkTbkPid } from "../../../utils/validator";
import { checkPer } from "@/utils/index";
import DrawerLevelAudit from "@/components/admin/user/drawer-level-audit";

// 审核中 表头
const firstColumns = [
 /* {
    title: "渠道",
    fixed: 'left',
    align: "center",
    scopedSlots: { customRender: "channel_tag" }
  },*/
  {
    title: "手机号",
    fixed: 'left',
    dataIndex: "mobile",
    align: "center",
    width: 120
  },
  {
    title: "昵称",
    dataIndex: "nickname",
    align: "center"
  },
  {
    title: "原有评级",
    align: "center",
    scopedSlots: { customRender: "before_level_slot" }
  },
  {
    title: "申请时间",
    dataIndex: "create_time",
    align: "center"
  },
  {
    title: "操作",
    align: "center",
    fixed: 'right',
    width: 120,
    scopedSlots: { customRender: "operation" }
  }
];
// 审核记录 表头
const secondColums = [
 /* {
    title: "渠道",
    align: "center",
    fixed: 'left',
    scopedSlots: { customRender: "channel_tag" }
  },*/
  {
    title: "手机号",
    fixed: 'left',
    dataIndex: "mobile",
    align: "center",
    width: 120
  },
  {
    title: "昵称",
    dataIndex: "nickname",
    align: "center"
  },
  {
    title: "原有评级",
    align: "center",
    scopedSlots: { customRender: "before_level_slot" }
  },
  {
    title: "审核结果",
    scopedSlots: { customRender: "level_slot" },
    align: "center"
  },
  {
    title: "审核人",
    dataIndex: "audit_user_name",
    align: "center"
  },
  {
    title: "审核时间",
    dataIndex: "create_time",
    align: "center"
  },
  {
    title: "申请时间",
    dataIndex: "apply_time",
    align: "center"
  },
  {
    title: "操作",
    fixed: 'right',
    align: "center",
    scopedSlots: { customRender: "record-operation" },
    width: 120
  }
];

export default {
  name: "LevelAudit",
  components: {
    DrawerLevelAudit
  },
  data() {
    return {
      tableScrollY: 600,
      USER_STATUS,
      checkTbkPid,
      form: this.$form.createForm(this),
      firstColumns,
      secondColums,
      columns: null,
      postingTb: false,
      isFirstLoad: true,
      list: [],
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      },
      listLoading: false,
      // 审核
      auditDialogVisible: false,
      auditForm: this.$form.createForm(this),
      currentTab: "1",
      // 二次确认弹窗显示标志
      confirmDialogVisiable: false,
      // 当前审核中的数据
      auditingData: null,
      posting: false,
      searchParams: {
        auth_type: undefined,
        create_type: undefined,
        examine_status:0,
      },
      pid: null,
      // 新增的
      currentAuditData: {},
      currentLevelId: null, // 当前审核id
      currentLevelDrawerType: 'audit'
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight -370;
  },
  mounted() {
    this.getAuditList();
  },
  methods: {
    // 重新查询
    async getNewTaobao() {
      this.postingTb = true;
      const data = { audit_id: this.auditingData.id };
      const { err, res } = await getTaobaoFans(data);
      this.postingTb = false;
      if (!err) {
        const _data = res.data;
        this.userinfo.anchor_name_from_yihoc = _data.anchor_name_from_yihoc
          ? _data.anchor_name_from_yihoc
          : null;
        this.userinfo.fans_count = _data.fans_count
          ? _data.fans_count
          : null;
        this.userinfo.rank = _data.rank ? _data.rank : null;
        this.userinfo.areas =
          _data.areas && _data.areas.length ? _data.areas.join(",") : "";
        if (res.success) {
          this.userinfo.update_time = _data.update_time
            ? _data.update_time
            : null;
          this.$message.success("查询成功，结果已更新");
        }
      }
    },
    // tab切换回调
    tabChangeCallback(e) {
      this.currentTab = e;
      if(e==='2'){
        this.searchParams.auth_type = undefined
        this.searchParams.create_type = 3
        this.searchParams.examine_status = undefined
      } else {
        this.searchParams.auth_type = undefined
        this.searchParams.create_type = 1
        this.searchParams.examine_status = 0
      }
      this.pagination.current = 1;
      this.searchParams.search = "";
      this.form.resetFields();
      this.getAuditList();
    },
    // 申请列表搜素表单submit
    handleFormSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, value) => {
        if (!err) {
          this.pagination.current = 1;
          this.searchParams.search = value.mdtz;
          this.searchParams.auth_type = value.auth_type;
          this.getAuditList();
        }
      });
    },
    // 获取申请列表
    async getAuditList() {
      if (!checkPer("user_manage_level_page")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      const data = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        search: this.searchParams.search,
        auth_type: this.searchParams.auth_type,
        create_type: this.searchParams.create_type,
        examine_status: this.searchParams.examine_status
      };
      if (this.currentTab === "1") {
        this.columns = this.firstColumns;
        data.ordering = "-create_time";
      } else if (this.currentTab === "2") {
        this.columns = this.secondColums;
        data.ordering = "-create_time";
      }
      this.listLoading = true;
      const { err, res } = await getUserLevelList(data);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getAuditList();
    },
    // 点击审核按钮
    handleAuditClick(id) {
      this.currentLevelId = id;
      this.currentLevelDrawerType = 'audit';
      this.auditDialogVisible = true;
    },
    // 隐藏审核弹层
    handleHideAuditModal(page) {
      this.auditDialogVisible = false;
      if(page === 0){
        this.pagination.current = 1;
        this.getAuditList()
      }
    },
    // 查看审核详情
    handleShowAuditDetailDrawer(id){
      this.currentLevelId = id;
      this.currentLevelDrawerType = 'readonly';
      this.auditDialogVisible = true;
    },
    // 审核form提交按钮点击
    handleShowAuditDrawerFormSubmit(e) {
      e.preventDefault();
      this.auditForm.validateFields(async formError => {
        if (!formError) {
          // 显示二次确认弹窗
          this.confirmDialogVisiable = true;
        }
      });
    },
    // 二次确认弹窗确定按钮点击，发起审核请求
    handleModalOkClick() {
      this.auditForm.validateFields(async (formError, values) => {
        if (!formError) {
          this.posting = true;
          values.status = this.status;
          values.pid = values.pid ? values.pid : undefined;
          const { err, res } = await auditUser(this.auditingData.id, values);
          this.posting = false;
          if (!err && res.success) {
            this.$message.success(res.message);
            this.auditDialogVisible = false;
            this.confirmDialogVisiable = false;
            this.auditingData = null;
            this.getAuditList();
          }
        }
      });
    },
    // 获取状态中文
    getStatusText(status) {
      let statusName = "";
      for (let key in USER_STATUS) {
        if (USER_STATUS[key].VALUE == status) {
          statusName = USER_STATUS[key].TEXT;
          break;
        }
      }
      return statusName;
    },
    // 切换审核记过单选
    handleRadioChange(e) {
      this.auditStatus = e.target.value;
    },
    // 搜索框数据发生改变
    handleFormInputChange(e) {
      if (e.target.value === "") this.getAuditList();
    },
    /**
     * pid检测方法
     * 在公共的检测方法之外再套一层逻辑，为了在“拒绝”状态下不检测pid
     */
    customCheckTbkPid(rule, value, cb) {
      if (
        this.auditForm.getFieldValue("status") === USER_STATUS.NOT_PASS.VALUE
      ) {
        return cb();
      }
      checkTbkPid(rule, value, cb);
    },
    // 检测拒绝原因
    checkFailReasons(rule, value, cb) {
      if (value == "") {
        cb("请输入拒绝原因");
      } else {
        cb();
      }
    },
    // 点击审核记录查看按钮
    handleShowAuditDrawer(record) {
      this.reviewDetail = record;
      this.reviewDialogVisiable = true;
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../../assets/less/var.less";
#audit {
  /deep/ .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    padding-bottom: 2px;
  }
  .filter-condition-form {
    height: 60px;
    .keyword-input {
      width: 300px;
    }
  }
  .passed {
    color: @green-color;
  }
  .not-pass {
    color: @red-color;
  }
  .fail-reasons-icon {
    margin-left: 5px;
    color: @red-color;
  }
}
</style>
<style lang='less'>
@import "../../../assets/less/var.less";
.audit-dialog {
  .ant-form-item {
    margin: 0;
  }
  .ant-modal-header {
    text-align: center;
  }
  .footer-btns {
    margin-top: 20px;
    text-align: center;
    button {
      margin: 0 10px;
    }
  }
  .status-text {
    font-size: 16px;
    margin-left: 15px;
    &.success-status {
      color: @green-color;
    }
    &.fail-status {
      color: @red-color;
    }
  }
  .cert-image {
    width: 100%;
  }
}
.confirm-modal {
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-title {
    text-align: center;
  }
  .approval-modal-content {
    text-align: center;
  }
  .ant-modal-footer {
    text-align: center;
    border-top: none;
  }
}
.flex-t {
  display: flex;
  justify-content: space-between;
}
.mr-20 {
  margin-right: 20px;
}
</style>
